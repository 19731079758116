<template>
<div>

  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Account Level">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            
            <b-row>

              <b-col md="6">
                <b-form-group label="Account Type" class="required">
                  <b-form-select v-model="form.account_type_id" @change="filterTable">
                    <b-form-select-option value="">Select Account Type</b-form-select-option>
                    <b-form-select-option :value="account_type._id" v-for="(account_type, ind) in account_types" :key="ind">{{account_type.name | capitalize}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Account Level"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Account Alias"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.alias"
                  />
                </b-form-group>
              </b-col>
              
            </b-row>

            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'account-level', params: { id: account_type_id }})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BBreadcrumb, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import vSelect from 'vue-select'



export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    BBreadcrumb,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        name : '',
        alias : '',
        id : '',
        account_type_id: ''
      },
      account_type_id: ''
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-account-level'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'account-level', params: { id: this.account_type_id }});
                });
                
            }
        });
      
    },

    getDetails(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/account-level-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                this.form.name    = data.name;
                this.form.alias    = data.alias;

                this.form.id = data._id;
                this.form.account_type_id = data.account_type_id;

                this.account_type_id = data.account_type_id;
                return this.form;
            }
        });
    },

    getAccountTypes(){
      return this.$store.dispatch(POST_API, {
        data:{},
        api: '/api/all-account-type-for-super-admin'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
        } else {
          var data = this.$store.getters.getResults.data;
          this.account_types = data;
          return this.account_types;
        }
      });
    },

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'DataBase',
      },{
        to:{name:'account-type'},
        text: 'Account Type',
      },{
        to:{name:'account-level', params: { id: this.account_type_id }},
        text: 'Account Level',
      },{
        to:null,
        text: 'Edit Account Level',
        active:true             
      }];
      return item;
    },    

  },
  mounted(){
    this.getAccountTypes().then(() => {
      this.getDetails();
    });
  }
}
</script>
